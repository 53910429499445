<template>
  <div id="transfer">
    <el-form
      style="padding:0"
      label-position="top"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item :label="labelName" class="is-required">
        <el-select
          v-model="select_ids"
          multiple
          placeholder="请选择"
          style="width: 100%"
          @change="select_change"
        >
          <el-option
            v-for="item in listNew"
            :key="item.id"
            :label="item.name +'@'+ item.domain_name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-col :span="16">
        <el-form-item :label="'选择'+type_name" style="margin-bottom:10px" class="is-required">
          <div style="display:flex;align-items: center;">
            <div style="flex: 1;">
              <el-input
                v-model="search"
                style="width:90%"
                size="small"
                placeholder="请输入"
                class="input-with-select"
                clearable
                @keyup.enter.native="get_transfer_list"
                @clear="get_transfer_list"
              />
            </div>
            <div>
              <button
                type="button"
                class="next-btn next-medium next-btn-normal isOnlyIcon"
                @click="get_transfer_list"
              >
                <span class="next-btn-helper"><i class="el-icon-refresh-right" /></span>
              </button>
            </div>
          </div>
        </el-form-item>

        <el-table
          id="table"
          :key="tableKey"
          ref="table"
          v-loading="loading"
          row-key="id"
          max-height="540"
          :header-cell-style="headerCallStyle"
          :data="tableData"
          style="width: 100%;"
          :stripe="false"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
        >
          <el-table-column width="70">
            <template slot="header">
              <form action="form">
                <input
                  id="chk"
                  v-model="checkedAll"
                  type="checkbox"
                  style="vertical-align: middle;margin-right:5px;cursor: pointer;"
                  @change="changeCheckedAll"
                >
                <label for="chk" style="vertical-align: middle;cursor: pointer;">全选</label>
              </form>
            </template>
            <template slot-scope="{row, $index}">
              <input
                v-model="row.checked"
                type="checkbox"
                style="cursor: pointer;"
                @change="changeChecked(row, $index)"
              >
            </template>
          </el-table-column>
          <template v-if="type==5">
            <el-table-column prop="secret_name" :label="type_name+'名称'" show-overflow-tooltip />
            <el-table-column prop="client_name" label="应用" show-overflow-tooltip />
          </template>

          <template v-else>
            <el-table-column prop="name" :label="type_name+'名称'" show-overflow-tooltip>
              <template slot-scope="{row}">
                {{ row.name }}
                <p v-if="row.display_name">{{ row.display_name }}</p>
              </template>
            </el-table-column>
            <el-table-column prop="domain_name" label="域" show-overflow-tooltip />
          </template>

          <el-table-column prop="remark" label="备注" show-overflow-tooltip />
        </el-table>
      </el-col>
      <el-col :span="8" style="padding-top: 46px;">
        <div
          dir="ltr"
          role="gridcell"
          class="aliyun-widget-col aliyun-widget-col-8"
        >
          <div class="WidgetRamManageMemberNew-auth-table-selected-panel-YsFqA">
            <div
              class="WidgetRamManageMemberNew-auth-table-selected-panel-title-KNfYc"
            >
              <span>已选择（{{ selectList.length }}）</span>
              <button
                v-if="selectList.length"
                type="button"
                class="next-btn next-medium next-btn-primary next-btn-text"
                style="margin-right: 8px; float: right"
                @click="clearable"
              >
                清空
              </button>
            </div>
            <ul class="WidgetRamManageMemberNew-auth-table-selected-panel-content-MGeA6" style="overflow: auto;">
              <li v-for="(item,index) in selectList" :key="index" style="margin:10px 0">
                <el-tag
                  type="info"
                  size="small"
                  closable
                  class="el-tag-box"
                  @close="$remove(item, index)"
                >
                  <p class="tag-text">{{ item.name }}</p>
                </el-tag>
              </li>
            </ul>
          </div>
        </div>
      </el-col>
    </el-form>
  </div>
</template>

<script>

import { get_all_group_list, get_all_sub_user_list } from '@/api'
import { get_all_roles } from '@/api/roles'
import { get_all_permissions, get_all_secrets } from '@/api/permissions'

export default {
  props: {
    type: {
      type: [String, Number],
      default: ''
    },
    listNew: {
      type: Array,
      default: () => []
    },
    labelType: {
      type: [String, Number],
      default: ''
    }
  },

  data () {
    return {
      ids: [],
      checkedAll: false,
      search: '',
      total: 0,
      tableKey: 1,
      rowIndex: null,
      loading: false,
      selectList: [], // 已选择的数据 表格加入
      tableData: [], // 表格数据
      select_ids: [Number(this.$route.query.id)], // 选择框 默认带出自己
      selectedDataId: []
    }
  },

  computed: {
    headerCallStyle () {
      return {
        background: '#eff3f8',
        color: '#333',
        fontWeight: 400,
        fontSize: '12px'
      }
    },
    type_name () {
      switch (this.type) {
        case '5':
          return 'secret'
        case '4':
          return '用户组'
        case '3':
          return '用户'
        case '2':
          return '角色'
        case '1':
          return '权限'
        default:
          return null
      }
    },
    labelName () {
      switch (this.labelType) {
        case '5':
          return 'secret'
        case '4':
          return '用户组'
        case '3':
          return '用户'
        case '2':
          return '角色'
        case '1':
          return '权限'
        default:
          return null
      }
    },
    be_added_type () {
      return this.$route.query.be_added_type
    }
  },
  watch: {
    be_added_type () {
      this.init()
      this.ids = []
      this.selectList = []
    },
    selectList: {
      deep: true,
      handler (newList) {
        this.ids = newList.map(({ id }) => id)
        this.$emit('change_transfer', this.selectList)
      }
    }
  },

  mounted () {
    this.init()
  },

  methods: {
    // 全选
    changeCheckedAll () {
      this.tableData.forEach(item => {
        this.checkedAll ? this.selectList.push(item) : this.selectList = this.selectList.filter(row => item.id != row.id)
        this.$set(item, 'checked', this.checkedAll)
      })
    },

    // 单选
    changeChecked (row, index) {
      if (row.checked) {
        this.selectList.push(row)
      } else {
        this.selectList = this.selectList.filter(item => item.id != row.id)
      }
    },

    $add (list) {
      this.selectList = this.tableData.filter(({ id }) => list.indexOf(id) > -1)
    },

    $remove (row) {
      this.selectList = this.selectList.filter(item => item.id != row.id)
      this.tableData.forEach(item => {
        if (item.id == row.id) {
          this.$set(item, 'checked', false)
        }
      })
    },

    // 选择组传递给父组件
    select_change (list) {
      this.$emit('select_change', list)
    },
    // 初始化数据
    init () {
      this.get_transfer_list()
      // this.reset()
      this.$emit('select_change', this.select_ids)
    },

    reset () {
      this.selectList = []
      this.rowIndex = null
      this.$emit('change_transfer', [])
    },

    clearable () {
      this.tableData.forEach(item => this.$set(item, 'checked', false))
      this.selectList = []
      this.checkedAll = false
      this.$updateTable()
    },

    $updateTable () {
      this.tableKey++
    },

    async get_transfer_list () {
      this.loading = true
      this.selectedDataId = this.selectList.map(({ id }) => id) || []

      if (this.type == 5) {
        const params = {
          search: this.search,
          domain_id: Number(this.$route.query.domain_id)
        }
        get_all_secrets(params).then(res => {
          this.loading = false
          this.tableData = res.data
        })
      } else if (this.type == 4) {
        const params = {
          domain_id: Number(this.$route.query.domain_id)
        }

        // 获取用户组
        await get_all_group_list(this.search, params).then(res => {
          this.loading = false
          this.tableData = res.data
        })
      } else if (this.type == 3) {
        const params = {
          domain_id: Number(this.$route.query.domain_id)
        }

        // 获取用户
        await get_all_sub_user_list(this.search, params).then(res => {
          this.loading = false
          this.tableData = res.data
        })
      } else if (this.type == 2) {
        // 获取角色
        const params = {
          search: this.search,
          base_info_domain_id: Number(this.$route.query.domain_id)
        }
        await get_all_roles(params).then(res => {
          this.loading = false
          this.tableData = res.data
        })
      } else {
        // 获取权限
        const params = {
          search: this.search,
          is_client_admin: this.$store.state.userInfo.is_client_admin,
          base_info_domain_id: Number(this.$route.query.domain_id)
        }
        await get_all_permissions(params).then(res => {
          this.loading = false
          this.tableData = res.data
        })
      }
      this.tableData.forEach(item => this.$set(item, 'checked', this.ids.indexOf(item.id) > -1))
      this.checkedAll = false
      this.$updateTable()
    }
  }
}
</script>

<style scoped>
.WidgetRamManageMemberNew-auth-table-selected-panel-YsFqA
  .WidgetRamManageMemberNew-auth-table-selected-panel-content-MGeA6 {
  border: 1px solid #d7d8d9;
  padding: 12px;
  height: 540px;
  /* overflow-y: auto; */
}

.WidgetRamManageMemberNew-auth-table-selected-panel-YsFqA {
  margin-left: 12px;
}
.WidgetRamManageMemberNew-auth-table-selected-panel-YsFqA
  .WidgetRamManageMemberNew-auth-table-selected-panel-title-KNfYc {
  padding-top: 8px;
  height: 36px;
  margin-bottom: 8px;
}

.WidgetRamManageMemberNew-auth-table-selected-panel-YsFqA
  .WidgetRamManageMemberNew-auth-table-selected-panel-title-KNfYc
  span {
  line-height: 24px;
}

.tag-text{
  width: calc(100% - 30px);
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}
.el-tag-box{
  display: flex;
  align-items: center;
}
.el-tag-box >>> .el-tag__close{
  font-size: 16px;
}
</style>

<style>
.table_row_active td{
  background: #f3faff !important;
}
th.gutter{
  background: rgb(239, 243, 248) !important;
}
</style>
