import request from '@/utils/request'

// 列表
export function roles_list (params) {
  return request({
    url: '/v1/permission/roles',
    method: 'get',
    params
  })
}
// 删除
export function rem_role (data) {
  return request({
    url: '/v1/permission/role',
    method: 'delete',
    data
  })
}

// 新增
export function add_role (data) {
  return request({
    url: '/v1/permission/role',
    method: 'post',
    data
  })
}

// ldap连接测试
export function test_connectivity (row) {
  return request({
    url: '/v1/setting/test_connectivity',
    method: 'get',
    params: row
  })
}

// 查看返回数据
export function get_info (id) {
  return request({
    url: '/v1/permission/role',
    method: 'get',
    params: { role_id: id }
  })
}

// 修改ldap数据
export function edit_role (data) {
  return request({
    url: '/v1/permission/role',
    method: 'put',
    data
  })
}

// 跨域拷贝
export function domain_assing (data) {
  return request({
    url: '/v1/permission/copy_roles',
    method: 'post',
    data
  })
}

// 获取全部角色
export function get_all_roles (params) {
  return request({
    url: '/v1/permission/get_all_roles',
    method: 'get',
    params
  })
}
