<template>
  <div class="tabs-content">
    <Loading :show="loading">
      <el-tabs v-model="be_added_type" type="card" @tab-click="handleClick">
        <el-tab-pane label="拥有的子组" name="4">
          <el-tabs v-model="tabs_item_children" type="card" style="margin-left:10px">
            <el-tab-pane label="直接拥有的组" name="1">
              <button
                type="button"
                class="next-btn next-medium next-btn-primary"
                style="margin-right: 8px"
                @click="add_groups"
              >
                <span class="next-btn-helper">添加子用户组</span>
              </button>
              <el-table
                :header-cell-style="headerCallStyle"
                :data="group_groups_direct_list"
                border
                style="width: 100%"
                class="table-box"
              >
                <el-table-column
                  prop="name"
                  label="用户组名称/显示名称"
                >
                  <template slot-scope="{ row }">
                    <button
                      type="button"
                      class="next-btn next-medium next-btn-primary next-btn-text"
                      style="margin-right: 8px"
                      @click="href(row, '/groups/handle')"
                    >
                      {{ row.name }}
                    </button>
                  </template>
                </el-table-column>
                <el-table-column prop="remark" label="备注" />
                <el-table-column label="操作">
                  <template slot-scope="{row}">
                    <button
                      type="button"
                      class="next-btn next-medium next-btn-primary next-btn-text"
                      style="margin-right: 8px"
                      @click="remove_group(row)"
                    >
                      <span class="next-btn-helper">移除授权</span>
                    </button>
                  </template>
                </el-table-column>
              </el-table>

            </el-tab-pane>
            <el-tab-pane label="间接拥有的组" name="2">
              <el-table
                :header-cell-style="headerCallStyle"
                :data="group_groups_indirect_list"
                border
                style="width: 100%"
                class="table-box"
              >
                <el-table-column
                  prop="name"
                  label="用户组名称/显示名称"
                >
                  <template slot-scope="{ row }">
                    <button
                      type="button"
                      class="next-btn next-medium next-btn-primary next-btn-text"
                      style="margin-right: 8px"
                      @click="href(row, '/groups/handle')"
                    >
                      {{ row.name }}
                    </button>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
        <el-tab-pane label="拥有的用户" name="3">
          <el-tabs v-model="tabs_item_children" type="card" style="margin-left:10px">
            <el-tab-pane label="直接拥有的用户" name="1">
              <button
                type="button"
                class="next-btn next-medium next-btn-primary"
                style="margin-right: 8px"
                @click="addUsers"
              >
                <span class="next-btn-helper">添加用户</span>
              </button>
              <el-table
                :header-cell-style="headerCallStyle"
                :data="group_users_direct_list"
                border
                style="width: 100%"
                class="table-box"
              >
                <el-table-column
                  label="用户名称/显示名称"
                >
                  <template slot-scope="{row}">
                    <button
                      type="button"
                      class="next-btn next-medium next-btn-primary next-btn-text"
                      style="margin-right: 8px"
                      @click="href(row, '/user/handle')"
                    >
                      {{ row.web_name }}
                    </button>
                  </template>
                </el-table-column>
                <el-table-column prop="remark" label="备注" />
                <el-table-column prop="address" label="操作">
                  <template slot-scope="{row}">
                    <button
                      type="button"
                      class="next-btn next-medium next-btn-primary next-btn-text"
                      style="margin-right: 8px"
                      @click="remove_group(row)"
                    >
                      <span class="next-btn-helper">移除授权</span>
                    </button>
                  </template>
                </el-table-column>
              </el-table>

            </el-tab-pane>
            <el-tab-pane label="间接拥有的用户" name="2">
              <el-table
                :header-cell-style="headerCallStyle"
                :data="group_users_indirect_list"
                border
                style="width: 100%"
                class="table-box"
              >
                <el-table-column
                  prop="web_name"
                  label="用户名称/显示名称"
                >
                  <template slot-scope="{row}">
                    <button
                      type="button"
                      class="next-btn next-medium next-btn-primary next-btn-text"
                      style="margin-right: 8px"
                      @click="href(row, '/user/handle')"
                    >
                      {{ row.web_name }}
                    </button>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
        <el-tab-pane label="拥有的角色" name="2">
          <el-tabs v-model="tabs_item_children" type="card" style="margin-left:10px">
            <el-tab-pane label="直接拥有的角色" name="1">
              <button
                type="button"
                class="next-btn next-medium next-btn-primary"
                style="margin-right: 8px"
                @click="drawer = true"
              >
                <span class="next-btn-helper">新增角色</span>
              </button>
              <el-table
                :header-cell-style="headerCallStyle"
                :data="group_roles_direct_list"
                border
                style="width: 100%"
                class="table-box"
              >
                <el-table-column
                  prop="name"
                  label="角色名称"
                >
                  <template slot-scope="{row}">
                    <button
                      type="button"
                      class="next-btn next-medium next-btn-primary next-btn-text"
                      style="margin-right: 8px"
                      @click="href(row, '/roles/handle')"
                    >
                      {{ row.name }}
                    </button>
                  </template>
                </el-table-column>
                <el-table-column prop="remark" label="备注" />
                <el-table-column label="操作">
                  <template slot-scope="{row}">
                    <button
                      type="button"
                      class="next-btn next-medium next-btn-primary next-btn-text"
                      style="margin-right: 8px"
                      @click="remove_group(row)"
                    >
                      <span class="next-btn-helper">移除授权</span>
                    </button>
                  </template>
                </el-table-column>
              </el-table>

            </el-tab-pane>
            <el-tab-pane label="间接拥有的角色" name="2">
              <el-table
                :header-cell-style="headerCallStyle"
                :data="group_roles_indirect_list"
                border
                style="width: 100%"
                class="table-box"
              >
                <el-table-column
                  prop="name"
                  label="角色名称"
                >
                  <template slot-scope="{row}">
                    <button
                      type="button"
                      class="next-btn next-medium next-btn-primary next-btn-text"
                      style="margin-right: 8px"
                      @click="href(row, '/roles/handle')"
                    >
                      {{ row.name }}
                    </button>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
        <el-tab-pane label="拥有的权限" name="1">
          <el-tabs v-model="tabs_item_children" type="card" style="margin-left:10px">
            <el-tab-pane label="直接拥有的权限" name="1">
              <button
                type="button"
                class="next-btn next-medium next-btn-primary"
                style="margin-right: 8px"
                @click="drawer = true"
              >
                <span class="next-btn-helper">新增权限</span>
              </button>
              <el-table
                :header-cell-style="headerCallStyle"
                :data="group_permissions_direct_list"
                border
                style="width: 100%"
                class="table-box"
              >
                <el-table-column
                  prop="name"
                  label="权限名称"
                >
                  <template slot-scope="{row}">
                    <button
                      type="button"
                      class="next-btn next-medium next-btn-primary next-btn-text"
                      style="margin-right: 8px"
                      @click="href(row, '/policies/handle')"
                    >
                      {{ row.name }}
                    </button>
                  </template>
                </el-table-column>
                <el-table-column prop="remark" label="备注" />
                <el-table-column label="操作">
                  <template slot-scope="{row}">
                    <button
                      type="button"
                      class="next-btn next-medium next-btn-primary next-btn-text"
                      style="margin-right: 8px"
                      @click="remove_group(row)"
                    >
                      <span class="next-btn-helper">移除授权</span>
                    </button>
                  </template>
                </el-table-column>
              </el-table>

            </el-tab-pane>
            <el-tab-pane label="间接拥有的权限" name="2">
              <el-table
                :header-cell-style="headerCallStyle"
                :data="group_permissions_indirect_list"
                border
                style="width: 100%"
                class="table-box"
              >
                <el-table-column
                  prop="name"
                  label="权限名称"
                >
                  <template slot-scope="{row}">
                    <button
                      type="button"
                      class="next-btn next-medium next-btn-primary next-btn-text"
                      style="margin-right: 8px"
                      @click="href(row, '/policies/handle')"
                    >
                      {{ row.name }}
                    </button>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
      </el-tabs>
    </Loading>
    <el-drawer
      ref="drawer"
      custom-class="drawer-warp"
      :size="700"
      :title="tabName[be_added_type]"
      :visible.sync="drawer"
    >
      <div class="drawer__content">
        <!-- <el-alert
          :title="tabName[be_added_type]"
          type="warning"
          :closable="false"
          show-icon
        /> -->
        <transfer
          ref="transfer"
          label-type="4"
          :list-new="group_list"
          :type="be_added_type"
          @change_transfer="change_transfer"
          @select_change="select_change"
        />

      </div>
      <div class="panel-footer-drawer custom-panel-footer">
        <div class="btn-con" style="float: left">
          <el-button
            type="button"
            class="next-btn next-medium next-btn-primary"
            style="margin-right: 8px"
            :loading="loading_but"
            @click="add"
          >
            <span class="next-btn-helper">确定</span>
          </el-button>
          <button
            type="button"
            class="next-btn next-medium next-btn-normal"
            style="margin-right: 8px"
            @click="$refs.drawer.closeDrawer()"
          >
            <span class="next-btn-helper">取消</span>
          </button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>

import transfer from '@/components/transfer'
import axios from '@/utils/request'
import { add_permission, del_permission, get_all_group_list } from '@/api'

export default {
  inject: ['reload'],

  components: {
    transfer
  },

  data () {
    return {
      drawer: false,
      loading: false,
      loading_but: false,
      be_added_type: '4',
      tabs_item_children: '1',
      tabName: { '4': '添加子用户组', '3': '添加用户', '2': '新增角色', '1': '新增权限' },
      group_users_direct_list: [], // 获取直接授予用户组的用户
      group_users_indirect_list: [], // 获取建接授予用户组的用户
      group_groups_direct_list: [], //  获取直接授予用户组的用户组
      group_groups_indirect_list: [], //  获取直接授予用户组的用户组
      group_roles_direct_list: [], //  获取直接授予用户组的角色
      group_roles_indirect_list: [], //  获取间接授予用户组的角色
      group_permissions_indirect_list: [], //  获取间接授予用户组的权限
      group_permissions_direct_list: [], //  获取间接授予用户组的权限
      add_to_id: [],
      be_added_id: [],
      group_list: [],
      Ole: []
    }
  },
  computed: {
    headerCallStyle () {
      return {
        background: '#eff3f8',
        color: '#333',
        fontWeight: 400,
        fontSize: '12px'
      }
    }
  },
  created () {
    this.init()
    this.$router.push({
      query: Object.assign({ be_added_type: this.be_added_type }, this.$route.query)
    })
    const params = {
      domain_id: Number(this.$route.query.domain_id)
    }
    get_all_group_list('', params).then(res => {
      this.group_list = res.data
    })
  },
  mounted () {},
  methods: {
    href ({ id, domain_id }, url) {
      this.$router.push({
        path: url,
        query: {
          id,
          domain_id,
          be_added_type: this.$route.query.be_added_type
        }
      })
      if (this.$route.path == url) this.reload()
    },
    init () {
      // 获取直接
      this.group_users_direct()
      this.group_groups_direct()
      this.group_roles_direct()
      this.group_permissions_direct()

      // 获取间接
      this.group_users_indirect()
      this.group_groups_indirect()
      this.group_roles_indirect()
      this.group_permissions_indirect()
    },
    // 移除组
    remove_group (row) {
      const data = {
        'client_id': 1, // 应用id
        'grant_id': row.grant_id // 授权信息id
      }
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        del_permission(data).then(res => {
          if (res.code === 0) {
            this.$message({
              showClose: true,
              message: res.msg,
              type: 'success'
            })
          }
          this.init()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },

    change_transfer (list) {
      this.add_to_id = list.map(item => item.id)
      this.Ole = list.map(() => 2)
    },

    // 自组件选择组触发
    select_change (list) {
      this.be_added_id = list
    },
    add () {
      let postData = {}
      this.loading_but = true

      if (this.be_added_type == 4) {
        postData = {
          client_id: 1,
          be_added_type: 4,
          be_added_id: this.be_added_id,
          add_to_type: Number(this.be_added_type),
          add_to_id: this.add_to_id,
          user_type: []
        }
      } else if (this.be_added_type == 3) {
        postData = {
          client_id: 1,
          be_added_type: 3,
          be_added_id: this.add_to_id,
          add_to_type: 4,
          add_to_id: this.be_added_id,
          user_type: this.Ole
        }
      } else if (this.be_added_type == 2) {
        postData = {
          client_id: 1,
          be_added_type: 2,
          be_added_id: this.add_to_id,
          add_to_type: 4,
          add_to_id: this.be_added_id,
          user_type: this.Ole
        }
      } else {
        postData = {
          client_id: 1,
          be_added_type: 1,
          be_added_id: this.add_to_id,
          add_to_type: 4,
          add_to_id: this.be_added_id,
          user_type: this.Ole
        }
      }

      add_permission(postData).then(res => {
        this.loading_but = false
        if (res.code === 0) {
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'success'
          })
          this.drawer = false
          this.init()
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'error'
          })
        }
      }).catch(() => {
        this.loading_but = false
      })
    },

    // 添加用户
    add_groups () {
      this.drawer = true
    },
    addUsers () {
      this.drawer = true
    },

    handleClick () {
      this.tabs_item_children = '1'
      this.$router.push({
        query: Object.assign({}, this.$route.query, { be_added_type: this.be_added_type })
      })
      this.init()
    },

    // 获取直接授予用户组的用户
    group_users_direct () {
      this.loading = true
      axios({
        url: '/v1/permission/direct/group_users',
        method: 'get',
        params: {
          group_id: this.$route.query.id,
          user_type: 2
        }
      }).then(res => {
        this.loading = false
        console.log(res)
        this.group_users_direct_list = res.data
      }).catch(() => {
        this.loading = false
      })
    },
    // 获取间接授予用户组的用户
    group_users_indirect () {
      this.loading = true
      axios({
        url: '/v1/permission/indirect/group_users',
        method: 'get',
        params: {
          group_id: this.$route.query.id,
          user_type: this.$store.state.userInfo.user_type
        }
      }).then(res => {
        this.loading = false
        console.log(res)
        this.group_users_indirect_list = res.data
      }).catch(() => {
        this.loading = false
      })
    },
    // 获取直接授予用户组的用户组
    group_groups_direct () {
      this.loading = true
      axios({
        url: '/v1/permission/direct/group_groups',
        method: 'get',
        params: {
          group_id: this.$route.query.id
        }
      }).then(res => {
        this.loading = false
        console.log(res, '22222')
        this.group_groups_direct_list = res.data
      }).catch(() => {
        this.loading = false
      })
    },
    // 获取间接授予用户组的用户组
    group_groups_indirect () {
      this.loading = true
      axios({
        url: '/v1/permission/indirect/group_groups',
        method: 'get',
        params: {
          group_id: this.$route.query.id
        }
      }).then(res => {
        this.loading = false
        console.log(res, '/indirect/group_groups')
        this.group_groups_indirect_list = res.data
      }).catch(() => {
        this.loading = false
      })
    },
    // 获取直接授予用户组的角色
    group_roles_direct () {
      this.loading = true
      axios({
        url: '/v1/permission/direct/group_roles',
        method: 'get',
        params: {
          group_id: this.$route.query.id
        }
      }).then(res => {
        this.loading = false
        console.log(res, '直接拥有的角色')
        this.group_roles_direct_list = res.data
      }).catch(() => {
        this.loading = false
      })
    },
    // 获取间接授予用户组的角色
    group_roles_indirect () {
      this.loading = true
      axios({
        url: '/v1/permission/indirect/group_roles',
        method: 'get',
        params: {
          group_id: this.$route.query.id
        }
      }).then(res => {
        this.loading = false
        console.log(res, 'indirect')
        this.group_roles_indirect_list = res.data
      }).catch(() => {
        this.loading = false
      })
    },
    // 获取间接授予用户组的角色
    group_permissions_direct () {
      this.loading = true
      axios({
        url: '/v1/permission/direct/group_permissions',
        method: 'get',
        params: {
          group_id: this.$route.query.id
        }
      }).then(res => {
        this.loading = false
        console.log(res)
        this.group_permissions_direct_list = res.data
      }).catch(() => {
        this.loading = false
      })
    },
    // 获取间接授予用户组的权限
    group_permissions_indirect () {
      this.loading = true
      axios({
        url: '/v1/permission/indirect/group_permissions',
        method: 'get',
        params: {
          group_id: this.$route.query.id
        }
      }).then(res => {
        this.loading = false
        console.log(res)
        this.group_permissions_indirect_list = res.data
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>
