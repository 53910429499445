<template>
  <div class="userinfo-container">
    <div class="header-title">
      <i class="el-icon-back bach" @click="previous" />
      <span style="vertical-align: middle;">{{ orderInfo.name }}</span>
    </div>
    <div class="userinfo-box">
      <div data-spm="userinfo">
        <div class="next-loading next-loading-inline sc-khAkCZ jIEYGu">
          <div class="next-loading-wrap">
            <div class="sc-iBaQBe cGFAXW">
              <div class="sc-eggMyH fLwpMi">
                <h3>用户基本信息</h3>
                <div style="margin-left: 16px">
                  <button
                    type="button"
                    class="next-btn next-medium next-btn-primary next-btn-text"
                    style="margin-right: 8px"
                    @click="edit"
                  >
                    <span class="next-btn-helper">编辑基本信息</span>
                  </button>
                </div>
              </div>
              <div class="next-row next-row-wrap wind-rc-data-fields">
                <form-label label="用户组名称"> {{ orderInfo.name ||'-' }} </form-label>
                <form-label label="GID"> {{ orderInfo.id }} </form-label>
                <form-label label="显示名称"> {{ orderInfo.display_name ||'-' }} </form-label>
                <form-label label="创建时间"> {{ orderInfo.create_time }} </form-label>
                <form-label label="所属组层级"> {{ orderInfo.parnet_group_info||'-' }} </form-label>
                <form-label label="备注"> {{ orderInfo.remark||'-' }} </form-label>
                <form-label label="域"> {{ orderInfo.domain_name||'-' }} </form-label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <tabs-content :default-obj="defaultObj" />

    <!-- 编辑基本信息 -->
    <el-drawer
      ref="drawer"
      size="30"
      custom-class="drawer-warp"
      title="编辑基本信息"
      :visible.sync="editDrawer"
    >
      <div class="drawer__content">
        <el-form
          ref="ruleForm"
          label-position="top"
          :model="form"
          :rules="rules"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="用户组名称" prop="name" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
            <el-input v-model="form.name" />
          </el-form-item>
          <el-form-item label="显示名称">
            <el-input v-model="form.display_name" />
          </el-form-item>
          <!-- <el-form-item label="所属父祖">
            <el-select v-model="form.parent_group_id" placeholder="请选择" style="width:100%">
              <el-option
                v-for="item in parentList"
                :key="item.id"
                :label="item.name"
                :value="item.id" />
            </el-select>
          </el-form-item> -->
          <el-form-item label="备注">
            <el-input
              v-model="form.remark"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 4}"
              placeholder="请输入内容" />
          </el-form-item>
        </el-form>
      </div>
      <div class="panel-footer-drawer custom-panel-footer">
        <div class="btn-con" style="float: left">
          <el-button
            class="next-btn next-medium"
            type="primary"
            @click="submit('ruleForm')"
          >确定</el-button>
          <button
            type="button"
            class="next-btn next-medium next-btn-normal"
            style="margin-right: 8px"
            @click="$refs.drawer.closeDrawer()"
          >
            <span class="next-btn-helper">取消</span>
          </button>
        </div>
      </div>
    </el-drawer>

  </div>
</template>

<script>
import { get_group, edit_group } from '@/api'
import tabsContent from './tabs_content.vue'

export default {
  components: {
    tabsContent
  },
  data () {
    return {
      defaultObj: {},

      editDrawer: false,
      orderInfo: {},
      tableData: [],
      parentList: [],
      form: {},
      rules: {}
    }
  },
  computed: {
    headerCallStyle () {
      return {
        background: '#eff3f8',
        color: '#333',
        fontWeight: 400,
        fontSize: '12px'
      }
    }
  },
  created () {
    this.get_info_user()
    // this.get_menu()
  },
  methods: {
    // async get_menu () {
    //   const params = { group_id: this.$route.query.id }
    //   const { data } = await group_drop_down_menu(params)
    //   this.parentList = data
    // },
    get_info_user () {
      const params = {
        group_id: this.$route.query.id
      }
      this.loading = true
      get_group(params).then(res => {
        this.loading = false
        this.orderInfo = res.data
        console.log(res)
      })
    },
    edit () {
      this.editDrawer = true
      this.form = JSON.parse(JSON.stringify(this.orderInfo))
    },
    submit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const params = {
            ...this.form,
            group_id: this.form.id,
            parent_group_id: this.form.parent_group_id
          }
          edit_group(params).then(res => {
            this.editDrawer = false
            this.get_info_user()
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    previous () {
      this.$router.push('/groups')
    }
  }
}
</script>

<style scoped>
.userinfo-container >>> .el-tabs__item {
  font-weight: 400 !important;
}
.userinfo-box {
  padding-top: 25px;
  color: #333;
}
.next-loading {
  width: 100%;
}
.fLwpMi {
  margin-bottom: 12px;
  white-space: nowrap;
}
.fLwpMi > h3 {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
}
h3,
h4 {
  margin-bottom: 7px !important;
  font-size: 14px;
}
.fLwpMi div:first-of-type {
  display: inline-block;
  margin-left: 40px;
}
.cGFAXW {
  margin-bottom: 20px;
}
.fLwpMi {
  margin-bottom: 12px;
  white-space: nowrap;
}
.eEgMuO {
  display: flex;
  margin-bottom: 8px;
  width: 50%;
  min-width: 300px;
}
.TJhJs {
  display: flex;
  flex-wrap: wrap;
  margin: 0px;
  padding: 0px;
  width: 100%;
}
.eRLVLq {
  margin-right: 16px;
  width: 164px;
}
</style>
